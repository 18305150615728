import React from 'react';
import styled from 'styled-components';

const NoticeCard = props => {
  const { title, content, Icon, link } = props;

  return (
    <NoticeCardWrapper href={link} target="_blank">
      <div>
        <Icon />
        <br /> <br />
        <h3>{title}</h3>
        <p>{content}</p>
      </div>
    </NoticeCardWrapper>
  );
};

const NoticeCardWrapper = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  width: 30rem;
  height: 24rem;
  padding: 2.4rem;
  margin: 2.4rem;

  border-radius: 8px;
  background-color: #2a2a2a;
  color: ${({ theme: { colors } }) => colors.white};

  .notice-link {
    text-align: center;
  }

  svg {
    font-size: 3.2rem;
    margin-bottom: 1rem;
    color: white;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  @media ${({ theme: { mediaQuery } }) => mediaQuery.mobileS} {
    margin: 2.4rem 0 2.4rem 0;
  }
`;

export default NoticeCard;
