import SpoqaHanSansNeoRegularWoff from '../assets/fonts/SpoqaHanSansNeo-Regular.woff';
import SpoqaHanSansNeoMediumWoff from '../assets/fonts/SpoqaHanSansNeo-Medium.woff';
import SpoqaHanSansNeoBoldWoff from '../assets/fonts/SpoqaHanSansNeo-Bold.woff';

export default `
  @font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    src: url(${SpoqaHanSansNeoRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    src: url(${SpoqaHanSansNeoMediumWoff}) format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    src: url(${SpoqaHanSansNeoBoldWoff}) format('woff');
  }
`;
