import React from 'react';
import { Link } from 'react-scroll';

const SectionLink = ({ sectionName, onClose }) => {
  return (
    <Link
      onClick={onClose && onClose}
      className="section_link"
      activeClass="active"
      to={sectionName}
      spy={true}
      smooth={true}
      offset={-30}
      duration={500}
    >
      {sectionName}
    </Link>
  );
};

export default SectionLink;
