import React, { useState } from 'react';
import { MobileSectionListWrapper } from './headerStyle';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import SectionLink from './SectionLink';

const MobileSectionList = ({ sectionNames }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickMenu = () => setIsOpen(true);
  const onClickClose = () => setIsOpen(false);

  return (
    <MobileSectionListWrapper>
      {isOpen ? <AiOutlineClose onClick={onClickClose} /> : <GiHamburgerMenu onClick={onClickMenu} />}
      {isOpen && (
        <ul className="mobile-section-list">
          {sectionNames.map(el => {
            return (
              <li>
                <SectionLink onClose={onClickClose} sectionName={el} />
              </li>
            );
          })}
        </ul>
      )}
    </MobileSectionListWrapper>
  );
};

export default MobileSectionList;
