import React from 'react';
import { useFadeDom } from '../../hook/useFadeDom';

import styled from 'styled-components';

import NoticeCard from './NoticeCard';

import { noticeCardList } from '../../data/data';

const Notice = () => {
  const { isVisible, domRef } = useFadeDom();

  return (
    <NoticeWrapper id="NOTICE" className="section">
      <div ref={domRef} className={`inner fade-in-section ${isVisible ? 'is-visible' : ''}`}>
        <h2>NOTICE</h2>
        <br/>
        <h3>반지하게임즈의 최신 소식을 살펴 보세요.</h3>

        <NoticeList>
          {noticeCardList.map(el => {
            return <NoticeCard key={el.noticeCardId} {...el} />;
          })}
        </NoticeList>
      </div>
    </NoticeWrapper>
  );
};

const NoticeWrapper = styled.section`
  height: auto;
`;

const NoticeList = styled.div`
  display: flex;
  
  @media ${({ theme: { mediaQuery } }) => mediaQuery.tablet} {
    flex-direction: column;
  }
`;

export default Notice;
