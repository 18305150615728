import React from 'react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

const ScrollProgressBar = () => {
  const [scrolled, setScrolled] = useState(0);

  const scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = `${(scrollPx / winHeightPx) * 100}%`;

    setScrolled(scrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollProgress);

    return () => {
      window.removeEventListener('scroll', scrollProgress);
    };
  }, []);

  return (
    <ProgressContainerWrapper>
      <ProgressBarWrapper scrolled={scrolled} />
    </ProgressContainerWrapper>
  );
};

const ProgressContainerWrapper = styled.div`
  background: white;
  height: 5px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1111;
`;

const ProgressBarWrapper = styled.div`
  height: 5px;
  background: #f2bb2c;
  width: ${props => props.scrolled};
`;

export default ScrollProgressBar;
