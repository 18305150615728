import React, { useState } from 'react';
import { GameItemWrapper, LinkContainer } from './gameStyle';
import { ImAppleinc, ImAndroid } from 'react-icons/im';

const GameItem = props => {
  const { title, gameImage, appStore, playStore } = props;
  const [isActive, setIsActive] = useState(false);

  const onMouseOverImg = () => setIsActive(true);
  const onMouseLeaveImg = () => setIsActive(false);

  return (
    <GameItemWrapper>
      <div className="game-image" onMouseOver={onMouseOverImg} onMouseLeave={onMouseLeaveImg}>
        <img src={gameImage} alt={`${title}`} />
        {isActive && (
          <LinkContainer>
            {appStore && (
              <a href={appStore} rel="noopener noreferrer" target="_blank">
                <ImAppleinc />
              </a>
            )}
            {playStore && (
              <a href={playStore} rel="noopener noreferrer" target="_blank">
                <ImAndroid />
              </a>
            )}
          </LinkContainer>
        )}
      </div>
      <div className="game-title">{title}</div>
    </GameItemWrapper>
  );
};

export default GameItem;
