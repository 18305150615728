import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  position: fixed;
  top: -50px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  height: 124px;
  margin: 0 auto;
  padding: 50px 48px 0;
  z-index: 1101;
  transform: translateY(0%) translateZ(0px);
  color: white;
  background-color: black;
`;

export const SectionListWrapper = styled.div`
  display: block;
  .link-list {
    display: flex;
    font-weight: 700;

    li {
      cursor: pointer;
      padding: 0 1rem 0 1rem;
    }
  }

  @media ${({ theme: { mediaQuery } }) => mediaQuery.mobileL} {
    display: none;
  } ;
`;

export const MobileSectionListWrapper = styled.div`
  display: none;

  .mobile-section-list {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    top: 100%;
    left: 0;
    background-color: black;
  }

  li {
    padding: 1rem 0 1rem 0;
    font-weight: 700;
  }

  svg {
    font-size: 2rem;
  }

  @media ${({ theme: { mediaQuery } }) => mediaQuery.mobileL} {
    display: block;
  } ;
`;
