import styled from 'styled-components';

export const AboutWrapper = styled.section`
  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-bottom: 3rem;
    line-height: 6rem;
  }

  .content-section {
    text-align: center;
    color: white;
    font-size: 2rem;

    p {
      margin-bottom: 1rem;
    }
  }

  @media ${({ theme: { mediaQuery } }) => mediaQuery.tablet} {
    .title-container {
      line-height: 3rem;
    }
    .content-section {
      font-size: 1.4rem;
    }
  }
`;
