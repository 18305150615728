import React from 'react';
import { AboutWrapper } from './aboutStyle';
import { useFadeDom } from '../../hook/useFadeDom';

const About = () => {
  const { isVisible, domRef } = useFadeDom();

  return (
    <AboutWrapper id="ABOUT" className="section">
      <div ref={domRef} className={`inner fade-in-section ${isVisible ? 'is-visible' : ''}`}>
        <div className="title-container">
          <h3 className="section-title">ABOUT</h3>
          <h2>아류로 성공하느니</h2>
          <h2>오리지널로 망하자!</h2>
        </div>

        <div className="content-section">
          <p>반지하게임즈는 2017년도에 설립된 인디 게임 스타트업으로,</p>
          <p>반지하게임즈의 원동력인 반지하 자취방에서 즐겁게 게임을</p>
          <p>만들던 초심을 잊지 말자는 뜻을 담고 있습니다.</p>
        </div>
      </div>
    </AboutWrapper>
  );
};

export default About;
