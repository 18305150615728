import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import fonts from './Fonts';

import { background } from './animation';

const GlobalStyles = createGlobalStyle`
	${reset};
	${fonts};

	*,
	*::before,
	*::after {
			padding: 0;
			box-sizing: border-box;
			margin: 0;
	}

	html {
		font-size: 10px;
		background-color: black;
	}

	body {
		font-family: 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

		position : relative;
		overflow-x : hidden;
		width : 100%;
		max-height : 100%;
		font-size : 1.6rem;

		-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

		::-webkit-scrollbar {
			display: none;
    	scrollbar-width: none;
			-ms-overflow-style: none; 
	}

	a { 
		color: inherit;
		text-decoration: none;
	} 

	li {
		list-style: none;
	}

	h2 {
		font-weight: 700;
		font-size: 6rem;
		color: ${({ theme: { colors } }) => colors.white};
		margin-bottom: 1rem;

		@media ${({ theme: { mediaQuery } }) => mediaQuery.tablet} {
			font-size : 3rem;
		}
	}

	h3 {
		font-weight: 700;
		font-size: 3rem;
		color: ${({ theme: { colors } }) => colors.white};
		margin-bottom : 1rem;

		@media ${({ theme: { mediaQuery } }) => mediaQuery.tablet} {
			font-size : 1.5rem;
		}
	}

	.section {
		width : 100vw;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.inner {
		width : 80vw;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		z-index :10;
	}

	.particlesBg {
		width : 100%;
		height : 100%;
		position : absolute;
		background-color : black;
	}

	.section-title {
		z-index: 11;
		text-align: start;
		animation: ${background} 20s linear infinite;
		font-size: 3rem;
		font-weight: 700;
		background: ${({ theme: { gradient } }) => gradient.custom_1};
		background-size: 1000% 100%;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.fade-in-section {
		opacity: 0;
		transform: translateY(20vh);
		visibility: hidden;
		transition: opacity 0.6s ease-out, transform 0.6s ease-out;
		will-change: opacity, visibility;
	}
	
	.fade-in-section.is-visible {
		opacity: 1;
		transform: none;
		visibility: visible;
	}
`;

export default GlobalStyles;
