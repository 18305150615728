import styled from 'styled-components';

export const GamesWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30rem;
`;

export const GamesInner = styled.div`
  text-align: center;
  width: 70vw;
  z-index: 10;
  color: ${({ theme: { colors } }) => colors.white};

  h3 {
    padding-bottom: 1.8rem;
  }
`;

export const GameList = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const GameItemWrapper = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 20%;

  img {
    position: relative;
    width: 100%;
    border-radius: 8px;
  }

  .game-title {
    margin-top: 1rem;
    font-weight: 700;
  }

  .game-image {
    position: relative;
    width: 100%;
  }

  .links {
    position: absolute;
    top: 0;
    border-radius: 0.8rem;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 0, 0, 0.7);
  }

  @media ${({ theme: { mediaQuery } }) => mediaQuery.tabletL} {
    width: 33.3333%;
  }

  @media ${({ theme: { mediaQuery } }) => mediaQuery.mobileL} {
    width: 100%;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 0.8rem;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 0, 0, 0.7);

  svg {
    font-size: 3.5rem;
  }

  a {
    color: black;
    padding: 0.5rem;
    margin: 0.5rem;
    background-color: white;
    border-radius: 50%;
  }
`;
