import React from 'react';
import styled from 'styled-components';

import { POLICY_LIST } from '../../data/data';

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterItem>
        <FooterCompanyText>주식회사 반지하게임즈</FooterCompanyText>
        <FooterItemText>대표: 이유원 정윤지</FooterItemText>
        <FooterItemText>사업자 등록번호: 667-87-01841</FooterItemText>
        <FooterItemText>04057 서울특별시 마포구 신촌로14길 20(노고산동) 6층 반지하게임즈</FooterItemText>

        <FooterPolicyItem>
          {POLICY_LIST.map(({ id, label, url }) => (
            <FooterItemLink bold key={id} href={url}>
              {label}
            </FooterItemLink>
          ))}

          <FooterItemLink>개발자 연락처 : +82 2747 5048</FooterItemLink>
          <FooterItemLink href="mailto:banjihagames.help@gmail.com">문의 : banjihagames.help@gmail.com</FooterItemLink>
        </FooterPolicyItem>
      </FooterItem>
    </FooterWrapper>
  );
};

const FlexCenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FooterWrapper = styled(FlexCenterDiv)`
  width: 100%;
  padding-bottom: 10rem;
`;

const FooterItem = styled(FlexCenterDiv)`
  width: 100rem;
  z-index: 20;
  gap: 0.5rem 0;
`;

const FooterCompanyText = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  color: white;

  margin-bottom: 16px;
`;

const FooterItemText = styled.span`
  line-height: 2rem;
  color: white;
  
  font-size: 1.4rem;
  font-weight: ${({ bold }) => bold ? 700 : 500};
`;

const FooterItemLink = styled.a`
  line-height: 2rem;
  color: #ffd97a;

  font-size: 1.4rem;
  font-weight: bold;
`;

const FooterPolicyItem = styled(FlexCenterDiv)`
  margin-top: 4rem;

  flex-direction: column;
  gap: 0.5rem 0;
`;

export default Footer;
