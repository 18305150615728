import React from 'react';

import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import theme from './styles/theme';

import ProgressBar from './components/ProgressBar';
import Header from './components/Header/Header';
import Intro from './components/Intro/Intro';
import About from './components/About/About';
import Games from './components/Games/Games';
import Notice from './components/Notice/Notice';
// import Recruit from './components/Recruit/Recruit';
import Footer from './components/Footer/Footer';

import ParticlesBg from './components/ParticlesBg';

const App = () => (
  <>
    <ParticlesBg />

    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <ProgressBar />
      <Header />
      <Intro />
      <About />
      <Games />
      <Notice />
      {/* <Recruit /> */}
      <Footer />
    </ThemeProvider>
  </>
);

export default App;
