import styled from 'styled-components';
import { slideTop } from '../../styles/animation';

export const IntroWrapper = styled.section`
  padding-top: 15vw;

  .inner {
    animation: ${slideTop} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    color: white;

    .welcome-letter {
      font-weight: 700;
      font-size: ${({ theme: { fontSizes } }) => fontSizes.h1};
      font-size: 6rem;
    }

    .accumulate-title {
      margin: 2rem 0 0.5rem 0;
    }

    .accumulate-download {
      font-weight: 700;
      margin-left: 2rem;
      .number-counter {
        font-size: 4rem;
      }
    }
  }

  @media ${({ theme: { mediaQuery } }) => mediaQuery.tablet} {
    img {
      width: 300px;
    }
  }

  @media ${({ theme: { mediaQuery } }) => mediaQuery.mobileL} {
    padding-top: 20rem;
  }
`;
