import React from 'react';
import SectionLink from './SectionLink';
import { SectionListWrapper } from './headerStyle';

const SectionList = ({ sectionNames }) => {
  return (
    <SectionListWrapper>
      <li className="link-list">
        {sectionNames.map((el, idx) => {
          return (
            <li key={idx}>
              <SectionLink sectionName={el} />
            </li>
          );
        })}
      </li>
    </SectionListWrapper>
  );
};

export default SectionList;
