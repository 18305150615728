const fontSizes = {
  h1: '3.6rem',
  h2: '3rem',
  h3: '2.4rem',
  h4: '2rem',
  h5: '1.8rem',
};

const radius = {
  xSmall: '0.4rem',
  small: '0.6rem',
  base: '0.8rem',
  lg: '1rem',
  xl: '1.2rem',
  xxl: '1.4rem',
};

const deviceSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '450px',
  tablet: '768px',
  tabletL: '1024px',
};

const colors = {
  black: '#000000',
  white: '#FFFFFF',

  gray_1: '#222222',
  gray_2: '#767676',
  gray_3: '#F2F2F2',

  green_1: '#3cb46e',
  green_2: '#5BFE3E',

  blue_1: '#0366d6',
  blue_2: 'rgb(31, 140, 230)',

  red_1: '#FE0A00',
};

const gradient = {
  custom_1:
    'linear-gradient(90deg,rgba(150, 48, 30, 1) 0%,rgba(199, 201, 88, 1) 10%,rgba(28, 147, 46, 1) 20%,rgba(74, 165, 168, 1) 30%,rgba(89, 90, 165, 1) 40%,rgba(84, 16, 67, 1) 50%,rgba(28, 58, 63, 1) 60%,rgba(82, 175, 183, 1) 70%,rgba(78, 170, 76, 1) 80%,rgba(150, 48, 30, 1) 90%,rgba(199, 201, 88, 1) 100%);',
};

const mediaQuery = {
  mobileS: `only screen and (max-width: ${deviceSizes.mobileS})`,
  mobileM: `only screen and (max-width: ${deviceSizes.mobileM})`,
  mobileL: `only screen and (max-width: ${deviceSizes.mobileL})`,
  tablet: `only screen and (max-width: ${deviceSizes.tablet})`,
  tabletL: `only screen and (max-width: ${deviceSizes.tabletL})`,
};

const theme = {
  fontSizes,
  radius,
  colors,
  mediaQuery,
  gradient,
};

export default theme;
