import React from 'react';
import styled from 'styled-components';

import { HeaderWrapper } from './headerStyle';
import SectionList from './SectionList';
import MobileSectionList from './MobileSectionList';

const SECTION_NAMES = ['ABOUT', 'GAMES', 'NOTICE'];
// const SECTION_NAMES = ['ABOUT', 'GAMES', 'NOTICE', 'RECRUIT'];

const Header = () => (
  <HeaderWrapper>
    <LogoText>Banjihagames</LogoText>

    <SectionList sectionNames={SECTION_NAMES} />
    <MobileSectionList sectionNames={SECTION_NAMES} />
  </HeaderWrapper>
);

const LogoText = styled.span`
  font-size: 2rem;
  font-weight: 700;

  @media ${({ theme: { mediaQuery } }) => mediaQuery.mobileL} {
    .logo {
      font-size: 1.6rem;
    }
  } ;
`;

export default Header;
