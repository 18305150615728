import seoul_2033 from '../assets/images/games/seoul-2033.png';
import seoul_2033_sponsor from '../assets/images/games/app_ic_backer.png';
import farm_stone from '../assets/images/games/app_ic_farm_stone.png';
import wony_hero from '../assets/images/games/app_ic_hero.png';
import denma_universe from '../assets/images/games/app_ic_denma.png';
import seoul_2033_yousijin from '../assets/images/games/seoul-2033-yousijin.jpg';
import seoul_2033_shelter from '../assets/images/games/seoul-2033-shelter.png';
import mini_moon_shot from '../assets/images/games/mini-moon-shot.png';
import moon_shot from '../assets/images/games/moon-shot.png';
import other_world_RPG from '../assets/images/games/other-world-RPG.png';
import peace_land from '../assets/images/games/peace-land.png';
import other_world_piture_quiz from '../assets/images/games/other-world-piture-quiz.jpg';
import vain_metting from '../assets/images/games/vain-metting.jpg';
import vain_metting_2 from '../assets/images/games/vain-metting-2.png';
import dice_warrior from '../assets/images/games/dice-warrior.jpg';
import dice_warrior_2 from '../assets/images/games/dice-warrior-2.png';
import dice_empire from '../assets/images/games/dice-empire.jpg';
import 휴거_1992 from '../assets/images/games/휴거-1992.png';

import { IoLogoInstagram } from 'react-icons/io';
import { AiFillYoutube } from 'react-icons/ai';
import { FiMail } from 'react-icons/fi';

export const gameList = [
  {
    gameId: 'farmstone',
    title: '수확의 정석',
    gameImage: farm_stone,
    appStore: '',
    playStore: '',
  },
  {
    gameId: 'seoul2033',
    title: '서울 2033',
    gameImage: seoul_2033,
    appStore: 'https://apps.apple.com/kr/app/%EC%84%9C%EC%9A%B8-2033/id1439604101',
    playStore: 'https://play.google.com/store/apps/details?id=com.banjihagames.seoul2033&hl=ko',
  },
  {
    gameId: 'seoul2033backer',
    title: '서울 2033 : 후원자',
    gameImage: seoul_2033_sponsor,
    appStore: 'https://apps.apple.com/kr/app/%EC%84%9C%EC%9A%B8-2033-%ED%9B%84%EC%9B%90%EC%9E%90/id1447640916',
    playStore: 'https://play.google.com/store/apps/details?id=com.banjihagames.seoul2033_backer&hl=ko',
  },
  {
    gameId: 'wony',
    title: '연차 사유 : 히어로',
    gameImage: wony_hero,
    appStore:
      'https://apps.apple.com/kr/app/%EC%97%B0%EC%B0%A8-%EC%82%AC%EC%9C%A0-%ED%9E%88%EC%96%B4%EB%A1%9C/id1579157040',
    playStore: 'https://play.google.com/store/apps/details?id=com.banjihagames.wony_heroes&hl=ko',
  },
  {
    gameId: 'denma',
    title: '덴마 유니버스',
    gameImage: denma_universe,
    appStore: 'https://apps.apple.com/kr/app/%EB%8D%B4%EB%A7%88-%EC%9C%A0%EB%8B%88%EB%B2%84%EC%8A%A4/id1546692122',
    playStore: 'https://play.google.com/store/apps/details?id=com.banjihagames.denma_app&hl=ko',
  },
  {
    gameId: 20201020,
    title: '휴거 1992',
    gameImage: 휴거_1992,
    appStore: 'https://apps.apple.com/app/apple-store/id1531655568?pt=119372841&ct=banjihaweb&mt=8',
    playStore:
      'https://play.google.com/store/apps/details?id=com.banjihagames.rapture_1992&referrer=utm_source%3Dbanjihaweb%26utm_medium%3Dbanner',
  },
  {
    gameId: 14,
    title: '서울 2033 : 방공호',
    gameImage: seoul_2033_shelter,
    appStore: 'https://apps.apple.com/kr/app/%EC%84%9C%EC%9A%B8-2033-%EB%B0%A9%EA%B3%B5%ED%98%B8/id1526435650',
    playStore: 'https://play.google.com/store/apps/details?id=com.banjihagames.seoul2033_shelter',
  },
  {
    gameId: 3,
    title: '서울 2033 : 유시진',
    gameImage: seoul_2033_yousijin,
    appStore: 'https://apps.apple.com/kr/app/%EC%84%9C%EC%9A%B8-2033-%EC%9C%A0%EC%8B%9C%EC%A7%84/id1474819291',
    playStore: 'https://play.google.com/store/apps/details?id=com.banjihagames.seoul2033_zero&hl=ko',
  },
  {
    gameId: 13,
    title: '허언증 소개팅 2',
    gameImage: vain_metting_2,
    appStore: 'https://apps.apple.com/kr/app/%ED%97%88%EC%96%B8%EC%A6%9D-%EC%86%8C%EA%B0%9C%ED%8C%85-2/id1523816277',
    playStore: 'https://play.google.com/store/apps/details?id=com.banjihagames.fakesogaeting2&hl=ko',
  },
  {
    gameId: 12,
    title: '주사위 용사 오딧세이',
    gameImage: dice_warrior_2,
    appStore:
      'https://apps.apple.com/kr/app/%EC%A3%BC%EC%82%AC%EC%9C%84-%EC%9A%A9%EC%82%AC-%EC%98%A4%EB%94%A7%EC%84%B8%EC%9D%B4/id1493155120',
    playStore: 'https://play.google.com/store/apps/details?id=com.banjihagames.DiceHeroOdyssey',
  },
  {
    gameId: 4,
    title: '문샷',
    gameImage: moon_shot,
    appStore: '',
    playStore: 'https://play.google.com/store/apps/details?id=com.SemiBasement.MoonShootPro&hl=ko',
  },
  {
    gameId: 5,
    title: '도전! 골든달',
    gameImage: mini_moon_shot,
    appStore: 'https://apps.apple.com/us/app/%EB%8F%84%EC%A0%84-%EA%B3%A8%EB%93%A0%EB%8B%AC/id1469366442',
    playStore: 'https://play.google.com/store/apps/details?id=com.banjihagames.minimoonshot&hl=ko',
  },
  {
    gameId: 7,
    title: '중고로운 평화나라',
    gameImage: peace_land,
    appStore: '',
    playStore: 'https://play.google.com/store/apps/details?id=com.semibasement.jaquanpaik.joongrous_peaceland&hl=ko',
  },
  {
    gameId: 8,
    title: '허언증 소개팅',
    gameImage: vain_metting,
    appStore: '',
    playStore: 'https://play.google.com/store/apps/details?id=com.semibasement.popop.sogaeting&hl=ko',
  },
  {
    gameId: 6,
    title: '저세상 RPG',
    gameImage: other_world_RPG,
    appStore: '',
    playStore: 'https://play.google.com/store/apps/details?id=com.semibasement.jaquanpaik.jdongjuntoorpg&hl=ko',
  },
  {
    gameId: 10,
    title: '주사위 제국',
    gameImage: dice_empire,
    appStore: 'https://apps.apple.com/us/app/%EC%A3%BC%EC%82%AC%EC%9C%84-%EC%A0%9C%EA%B5%AD/id1479708197',
    playStore: 'https://play.google.com/store/apps/details?id=com.banjihagames.diceempire&hl=ko',
  },
  {
    gameId: 11,
    title: '주사위 용사',
    gameImage: dice_warrior,
    appStore: 'https://apps.apple.com/kr/app/%EC%A3%BC%EC%82%AC%EC%9C%84-%EC%9A%A9%EC%82%AC/id1440415401',
    playStore: 'https://play.google.com/store/apps/details?id=com.semibasement.diceHero&hl=ko',
  },
];

export const noticeCardList = [
  {
    noticeCardId: 1,
    title: 'Instagram',
    content: '반지하의 인스타그램! 반지하의 소식과 이벤트를 만나 보세요.',
    Icon: IoLogoInstagram,
    link: 'https://www.instagram.com/seoul2033/?hl=ko',
  },
  {
    noticeCardId: 2,
    title: 'Youtube Channel',
    content: '반지하의 공식 유튜브 채널입니다.',
    link: 'https://www.youtube.com/channel/UCEQPpWLgJ5Ce222E1Bq2dbg',
    Icon: AiFillYoutube,
  },
  {
    noticeCardId: 3,
    title: 'E-Mail',
    content: '다양한 문의 사항을 접수해 주세요!',
    Icon: FiMail,
    link: 'mailto:banjihagames.help@gmail.com',
  },
];

export const recruitmentList = [
  {
    recruitmentId: 1,
    recruitmentSection: '개발',
    content: 'Unity 개발자 모집',
    linkUrl: '',
    isOpen: false,
  },
  {
    recruitmentId: 2,
    recruitmentSection: '디자인',
    content: 'UI UX 디자이너 모집',
    link: 'https://www.notion.so/banjihagames/2b121659b3964bcd8fce92137b5cb82f',
    isOpen: true,
  },
  {
    recruitmentId: 3,
    recruitmentSection: '개발',
    content: 'React Native',
    link: '',
    isOpen: false,
  },
  {
    recruitmentId: 4,
    recruitmentSection: '기획',
    content: '기획자',
    link: '',
    isOpen: false,
  },
  {
    recruitmentId: 5,
    recruitmentSection: '디자인',
    content: '일러스트레이터',
    link: '',
    isOpen: false,
  },
  {
    recruitmentId: 6,
    recruitmentSection: '디자인',
    content: '캐릭터 제품 디자이너',
    link: '',
    isOpen: false,
  },
];

export const POLICY_LIST = [
  {
    id: 1,
    label: '서비스 이용 약관',
    url: 'https://policy.banjihagames.com/docs/1',
  },
  {
    id: 2,
    label: '개인정보처리방침',
    url: 'https://policy.banjihagames.com/docs/2',
  },
];
