import { useRef, useEffect, useState } from 'react';

const useFadeDom = () => {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef(null);

  useEffect(() => {
    const currentDom = domRef.current;

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });

    observer.observe(currentDom);

    return () => observer.unobserve(currentDom);
  }, []);

  return { isVisible, domRef };
};

export { useFadeDom };
