import React from 'react';
import { useFadeDom } from '../../hook/useFadeDom';

import { GamesWrapper, GamesInner, GameList } from './gameStyle';
import GameItem from './GameItem';

import { gameList } from '../../data/data';

const Games = () => {
  const { isVisible, domRef } = useFadeDom();

  return (
    <GamesWrapper id="GAMES">
      <GamesInner ref={domRef} className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}>
        <h2>GAMES</h2>
        <br/>
        <h3>반지하게임즈의 다양한 게임을 만나 보세요.</h3>

        <GameList>
          {gameList.map(el => {
            return <GameItem key={el.gameId} {...el} />;
          })}
        </GameList>
      </GamesInner>
    </GamesWrapper>
  );
};

export default Games;
