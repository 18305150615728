import { keyframes } from 'styled-components';

const slideTop = keyframes`
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
`;

const background = keyframes`
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
`;

export { slideTop, background };
