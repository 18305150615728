import React from 'react';
import CountUp from 'react-countup';

import { IntroWrapper } from './introStyle';
import banjihagames_logo from '../../assets/images/banjihagames-logo-mid.png';

const Intro = () => {
  return (
    <IntroWrapper className="section">
      <div className="inner">
        <img src={banjihagames_logo} alt="banjihagames logo" />
        
        <h3>반지하게임즈는</h3>
        <h3>독창적이고, 용기를 가지고, 재미있게 일합니다!</h3>

        <div className="accumulate-title">누적 다운로드 수</div>
        
        <div className="accumulate-download">
          <div>
            <CountUp className="number-counter" decimal="," separator="," end={'1850000'} />
            <span> 만</span>
          </div>
        </div>
      </div>
    </IntroWrapper>
  );
};

export default Intro;
